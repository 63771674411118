import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from 'gatsby';
import Sidebar from 'components/sidebar';
import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Links from 'components/links';
import Image from 'components/image';
import SEO from 'components/seo';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Project Kickoff`}</h1>
    <p><strong parentName="p">{`Day 1:`}</strong></p>
    <p>{`Let me tell you about one of the best things I've ever read.`}</p>
    <p>{`They're called the Boron Letters (`}<a parentName="p" {...{
        "href": "https://morgancrozier.com/boron-letters/"
      }}>{`here’s a link to all of them for free`}</a>{`, if you wanna read ‘em).`}</p>
    <p><strong parentName="p">{`Have you read ‘em? If not, here's a quick summary.`}</strong></p>
    <ul>
      <li parentName="ul">{`There's a guy named Gary Halpert`}</li>
      <li parentName="ul">{`He's one of the OGs of marketing, he cut his teeth as a marketer getting
people to buy things out of mail order ads`}</li>
      <li parentName="ul">{`He's one of the greatest copywriters of all time. Known as the "Prince of
Print". Copywriting is selling via written text.`}</li>
    </ul>
    <p>{`So he went to Boron Federal Prison Camp (for tax fraud I think) - and started
writing letters to his son, Bond. The letters were meant to teach his son
everything he knew about sales, marketing & copywriting (and life).`}</p>
    <p>{`There's like 25 of them. I found them one night, and read all of them. I was
hooked. The guy has a way with words. Every letter is addressed to his son Bond,
but the letters have helped millions of people become better sales/copywriters.`}</p>
    <p>{`Ever since then - I've secretly fantasized about handing down wisdom to my kid
via letters someday. Minus the prison part (I'd totally be someone's bitch).`}</p>
    <p>{`So here I am - sitting on my couch - I'm writing letters, sharing wisdom, and
watching my daughter (Blush) sleep on the baby monitor. Yea boi! the time is
now.`}</p>
    <p>{`So I'm going to address these letters for her.`}</p>
    <hr></hr>
    <p>{`Dear Blush,`}</p>
    <p>{`Today is Day 1 of the All Access Pass. I'm giving people a peek behind the
curtains, at how I launch new ventures.`}</p>
    <p><strong parentName="p">{`My day always starts the same:`}</strong></p>
    <ul>
      <li parentName="ul">{`Wim Hof breathing to wakeup my body (I do ˜5 minutes of
`}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=tybOi4hjZFQ"
        }}>{`this video`}</a>{`)`}</li>
      <li parentName="ul">{`3 minutes of gratitude (wake up my heart)`}</li>
      <li parentName="ul">{`3 minutes of focus on what I want to do today (wake up my mind)`}</li>
    </ul>
    <p>{`The whole thing takes about 10 minutes. I used to think I was too busy. Always
rushing to get somewhere. Then I heard Tony Robbins say: "If you don't have 10
minutes, you don't have a life". So now even if I'm running 20 minutes late, I
do the routine and run 30 minutes late instead.`}</p>
    <p>{`So let's get to today's focus. I have a couple different "to-do list"
techniques. I'll teach you some in the future. But today I'll share the one I do
most frequently.`}</p>
    <p><strong parentName="p">{`It's called One Big Thing.`}</strong></p>
    <p>{`Everyday - I choose one big thing to get done. Big is not about how long it
takes. It's `}<strong parentName="p">{`big impact.`}</strong>{` Sometimes that's a 5 minute phone call.`}</p>
    <p>{`I ask myself: "what's the one thing on my list...that if I nailed it, would make
the whole day a success?". That becomes my `}<strong parentName="p">{`One Big Thing (OBT)`}</strong>{` for the day.
I relentlessly focus on that, and feel zero guilt about ignoring other stuff.`}</p>
    <p>{`I learned this from reading about Peter Thiel. He's the creator of PayPal, and
one of the first investors in Facebook. The team from PayPal has gone on to
create a dozen+ billion dollar companies, including LinkedIn, YouTube, Yelp,
SpaceX, Tesla, Yammer, etc.. They are known as the Paypal Mafia, (which resulted
in this dorky looking magazine cover)`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "82.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAARABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAEDBAIF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQIA/9oADAMBAAIQAxAAAAHytGe+byoM1ybchP/EABkQAQEBAQEBAAAAAAAAAAAAAAIBABIyIv/aAAgBAQABBQKGTTmZT6LqyVEV7Q9P1v/EABURAQEAAAAAAAAAAAAAAAAAABBB/9oACAEDAQE/ASH/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAcEAABAwUAAAAAAAAAAAAAAAAAARAREiIxQVH/2gAIAQEABj8CuZSIQnfSrAj/AP/EAB4QAAICAgIDAAAAAAAAAAAAAAABMUERIRBRYaHh/9oACAEBAAE/IfghVavwKoW+hhhZJp5OFkZhpUj2iXj/2gAMAwEAAgADAAAAEHPvwP/EABkRAAMAAwAAAAAAAAAAAAAAAAABERAhwf/aAAgBAwEBPxBPVhTvH//EABkRAAMAAwAAAAAAAAAAAAAAAAABERAhQf/aAAgBAgEBPxBrZIdx/8QAHhABAQACAgIDAAAAAAAAAAAAAREAITFBEHFRkaH/2gAIAQEAAT8QCmigzZByJexdzTvAkMEQxUNKB0KS++8oz68EJIfH1hDDpDQGoZ+N4jwes//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Paypal Mafia",
          "title": "Paypal Mafia",
          "src": "/static/01db246b4bc292b3f119fa8dc55a3946/04bec/paypal-mafia.jpg",
          "srcSet": ["/static/01db246b4bc292b3f119fa8dc55a3946/a6407/paypal-mafia.jpg 400w", "/static/01db246b4bc292b3f119fa8dc55a3946/a24e6/paypal-mafia.jpg 800w", "/static/01db246b4bc292b3f119fa8dc55a3946/04bec/paypal-mafia.jpg 1600w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Anyways - Peter was quoted as saying his management style is very unique. He
gives everyone `}<strong parentName="p">{`ONE`}</strong>{` thing to focus on. `}<strong parentName="p">{`ONE`}</strong>{` problem to solve. And if they
try to talk to him about anything else - he literally gets up and leaves the
room.`}</p>
    <p>{`I thought that was hilarious, and a boss move. So I do it for my teams - and for
myself. `}<strong parentName="p">{`ONE`}</strong>{` thing a day. `}<strong parentName="p">{`ONE`}</strong>{` problem to solve.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "306px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "116.99346405228756%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAIAAACEf/j0AAAACXBIWXMAAAsTAAALEwEAmpwYAAADCUlEQVQ4y4VT3U9SYRg/V4oiIBiakihmqTlz2pxw4HD4PIcvAVG+FL+BQlF03eRFWZNwdtFaK1OvWq45t66iZG1ufa67/qd+h9dAK3X77d3zPOf9vc/v+TiUSWY2VDO0SA/DKGEZsdEgZkpgaoys1HQWKDC9bb5o75heZAjeCEVuRodavYjg9F8dBnAB755J9rX5o71RJA/3RJacmRXPysTAZNJ0e4FLR3qi+irDmWRBtpihq/RwLAqrRS6AlZgQh3tMkwlXYQuu7ASZRM21FhhQAZDKUS2B4BKjhj2GhC2TydsgeFqGvBof3hq+FvCoh2yX7Fw9b6/nyEnANfDWOhuklckg6CrpOJOYHpwOXB+5F1xN8+l528KMdvaOJQWg/oxrOePKpPnF2K0JZEI+6mQDSIU2pR3PA/xlBwADEUeTE/3nGxxIDve07KJyoWaxUDZdqdeJ9NBCV9K6CloYOOZf/EracYqMnLTIMN4Xi/VPINVI5yjKHu0KhrrDoe4Qihfu1JpJw0uTK5MxT6wK9sTf7p8cmEoYkyh1vD+GE8/9d9X+kV3NoJOl8RCdpdmcScYNbAXGIFQlFvIIs5WyZCrnkcmoIHXOEB9uH8bMIDhlSSWYBNqLxbggM/K4rrj5Roe72R3sCmHakZ4I1h5aLpZNekaAnwQntOAUlJ8vW+iW2OjVeLGe7mYPYFdyEOJWe875pSmTxAiYpSxdoY3TszPaab/Gt+xcSjKJRS49jv+8Uoev5NpfoIxyaxkKGzE4ldva4LA1Os1KzlhrZf/EWbnt5H1KGdslqI/tyqPbQN3YjjS0JQltyUJb8ugrRGSRbcWY8EkafgWjRKHk8T1AkdirmnptXv9oyR6qFva1D/L0Wt6WK+jX8syjD/ZcgdsomNcPtfffd959J5t7Q1iUNHUAyOYPRIn9weynvoeFjtW889mXwNZ334tvpidH829/BV7+YDePPM+/hnd+8k8/Vyf3ZUUW1eLKEbS6ck2OLKB2PlY5so18VlV0O/ybGk+ORNo8G3DVzmPKb0rNSrI3lklQAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "OBT Channel",
          "title": "OBT Channel",
          "src": "/static/be21e720fa248bed86be155406ca0db9/bed10/one-big-thing-channel.png",
          "srcSet": ["/static/be21e720fa248bed86be155406ca0db9/bed10/one-big-thing-channel.png 306w"],
          "sizes": "(max-width: 306px) 100vw, 306px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Today's `}<strong parentName="p">{`OBT`}</strong>{` is doing a proper kickoff for this new project.`}</p>
    <p><strong parentName="p">{`The first project & venture is to raise a `}{`$`}{`1M+ fund, so I can invest bigger
checks into startups. I can and will share more, but the specifics are not
important yet.`}</strong></p>
    <p>{`The important part is to `}<strong parentName="p">{`learn how to kick off`}</strong>{` any new project.`}</p>
    <p><strong parentName="p">{`There's 4 key components to my Kickoff Process:`}</strong></p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Define "Winning"`}</strong></li>
      <li parentName="ol"><strong parentName="li">{`Set "Anti-Goals"`}</strong></li>
      <li parentName="ol"><strong parentName="li">{`Back of the envelope - what does it take to get there?`}</strong></li>
      <li parentName="ol"><strong parentName="li">{`1 Hour, 1 Day, 1 Week, 1 Month`}</strong></li>
    </ol>
    <p>{`ˆRemember those blushie. There's no acronym. Acronyms are for wimps!`}</p>
    <p><strong parentName="p">{`Notice some key things that are not a part of my kickoff process:`}</strong></p>
    <ul>
      <li parentName="ul">{`research`}</li>
      <li parentName="ul">{`asking others if it's a good idea`}</li>
      <li parentName="ul">{`worrying`}</li>
      <li parentName="ul">{`grand vision statements (these come later)`}</li>
    </ul>
    <p><strong parentName="p">{`OK here's a link to`}</strong>{`
`}<a parentName="p" {...{
        "href": "https://docs.google.com/document/d/1wnZLXR3BDdyDZLLmcW3HkInJuC69s7ryDwHf1Sj2FOs/edit?usp=sharing"
      }}>{`the kickoff doc for the fund.`}</a>{`
`}<strong parentName="p">{`Before you go there - let me give you a quick rundown of how to do each of the
4 parts:`}</strong></p>
    <p><strong parentName="p">{`#1 - Define Winning`}</strong><br parentName="p"></br>{`
`}{`I set clear, measurable goals.`}</p>
    <p>{`Not some long winded monologue. Just 1-2 sentences about the outcome.`}</p>
    <p>{`It should be "measurable" (or "failable"). Meaning, if you showed this to
someone, they could figure out if you passed or failed.`}</p>
    <p>{`That means including a time frame and a # objective.`}</p>
    <p>{`"I want to achieve financial success and not stress about money!"`}<br parentName="p"></br>{`
`}<strong parentName="p">{`ˆterrible goal. what is 'success'? by when?`}</strong></p>
    <p>{`"I want to have `}{`$`}{`5M in liquid assets by age 35"`}<br parentName="p"></br>{`
`}<strong parentName="p">{`^a good simple goal`}</strong></p>
    <p>{`OK the last trick here. Ambitious people want to set big goals. Conservative
folks want to set modest goals. Which one should you do?`}</p>
    <p><strong parentName="p">{`Trick question - do both.`}</strong></p>
    <p>{`I think of "success" as a spectrum. A range. There's a minimum outcome, below
that, and I put in more than I got out. I call this my "good win". It's solid.`}</p>
    <p><strong parentName="p">{`Then I set a "F`}{`*`}{`ck Yea" goal. This is self explanatory. If I hit this
outcome, I would say f`}{`*`}{`ck yea. Great outcome.`}</strong></p>
    <p><strong parentName="p">{`#2 - Anti-Goals`}</strong><br parentName="p"></br>{`
`}{`There's an old saying when it comes to deal making. "You pick the price, I'll
pick the terms".`}</p>
    <p>{`Basically - even if you get the price you want. If the terms are screwed up.
You'll be screwed up.`}</p>
    <p>{`Like if your goal is to be a famous musician, and you're touring the world, but
you're unhealthy, your marriage is in shambles, and your kids don't recognize
you.. you won the battle but lost the war.`}</p>
    <p>{`I stole this from my new friend Andrew Wilkinson. Setting Anti-Goals.`}</p>
    <p>{`These are my parameters. I take 10 minutes to imagine all the plausible outcomes
or side effects of pursuing this goal. Then I write them down like you'll see on
the document.`}</p>
    <p><strong parentName="p">{`#3 - Back of the Envelope, how might I get there?`}</strong>{` I am a big fan of running
quick numbers on things early on. Even when I have no clue how to achieve my
goal - doing a quick calculation can show some of the options.`}</p>
    <p>{`If I want to make a million dollars. I can make a
$1 app and sell it to 1M users. Or I can create a $100k product and find 10
customers.`}</p>
    <p>{`The back of the envelope is meant to walk through some scenarios, and identify
which number seems "hard to hit". That becomes my focus. How do I get that
number?`}</p>
    <p>{`Then I brainstorm strategies to get to that number.`}</p>
    <p><strong parentName="p">{`#4 - 1 Hour, 1 Day, 1 Week, 1 Month`}</strong><br parentName="p"></br>{`
`}{`This is the most important step. Never leave the scene of a decision without an
action.`}</p>
    <p>{`This step is about brainstorming what you can do in specific time boxes.`}</p>
    <p>{`Most people over-think things. They start with something that will take weeks or
months to complete.`}</p>
    <p><strong parentName="p">{`I do the opposite. I ask myself:`}</strong><br parentName="p"></br>{`
`}{`"What's the 1 hour version of my plan?"`}<br parentName="p"></br>{`
`}{`"What's something I could do in the next hour that would inch me closer to my
first happy customer?"`}<br parentName="p"></br>{`
`}{`etc..`}</p>
    <p>{`Then I immediately do that thing. Momentum is the lifeblood of any project, so I
throw myself headfirst into creating initial momentum.`}</p>
    <p>{`I brainstorm things I can do, and start finding the "1 hour version" or "1 day
version" of those ideas.`}</p>
    <p><strong parentName="p">{`The 1 week and 1 month stuff doesn't matter, I keep those rough. I'm really
focused on the 1 hour and 1 day - because that's what I'm going to do first.`}</strong></p>
    <p>{`Alright - that's a lot to take in. Tomorrow I'll be shorter! Talk soon. Ciao -
shaan`}</p>
    <Links links={[{
      title: LinkLabel.Template,
      links: [{
        url: 'https://docs.google.com/document/d/1wnZLXR3BDdyDZLLmcW3HkInJuC69s7ryDwHf1Sj2FOs/edit?usp=sharing',
        label: 'Fund Kickoff Google Doc'
      }]
    }, {
      title: LinkLabel.Web,
      links: [{
        url: 'https://morgancrozier.com/boron-letters/',
        label: 'The Boron Letters'
      }, {
        url: 'https://www.youtube.com/watch?v=tybOi4hjZFQ',
        label: 'Guided Wim Hof Breathing'
      }]
    }]} mdxType="Links" />
    <PostFooter nextButton={{
      text: postMap.making_the_sales_map.label,
      slug: slugify(postMap.making_the_sales_map.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      